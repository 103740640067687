// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import map from 'lodash/map';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type { Prismic404Page } from '~schema';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'textWrapper'
  | 'title'
  | 'description'
  | 'button';

export type Props = {
  // $FlowFixMe --> Reason: fix classes issue
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<Prismic404Page>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,0.15)',
    background: ({ node }) =>
      `url(${node?.data?.background_image?.url}) no-repeat center center fixed`,
    backgroundSize: 'cover !important',
    backgroundBlendMode: 'overlay',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  textWrapper: {},
  title: {
    marginBottom: 0,
    fontWeight: 'initial',
    textShadow: '1px 1px 4px #858585',
  },
  description: {
    color: theme.palette.common.white,
    textShadow: '1px 1px 4px #6D6D6D',
  },
  button: {
    '* + &': {
      margin: theme.spacing(3, 0),
      minWidth: 180,
    },
  },
});

const NotFoundPage = ({ classes, node }: Props) => (
  <div className={classes.root}>
    <Container
      maxWidth="sm"
      component={ResPadding}
      vertical
      className={classes.wrapper}
    >
      <div className={classes.textWrapper}>
        {node?.data?.title?.text ? (
          <Typography
            variant="h1"
            color="secondary"
            gutterBottom
            className={classes.title}
          >
            {node?.data?.title?.text}
          </Typography>
        ) : null}
        <RichText
          {...node?.data?.description}
          className={classes.description}
        />
        {map(
          node?.data?.buttons,
          (button, index: number) =>
            button && (
              <Button
                key={index}
                component={FieldLink}
                variant="contained"
                color="primary"
                title={button?.label?.text}
                className={classes.button}
                field={button?.link}
              >
                {button?.label?.text || ''}
              </Button>
            ),
        )}
      </div>
    </Container>
  </div>
);

export default compose(
  withNodePage<Prismic404Page, *>({
    getNode: data => data?.prismic404Page,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery($prismicLocaleId: String) {
    prismic404Page(lang: { eq: $prismicLocaleId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
        description {
          html
        }
        background_image {
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1200, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        buttons {
          label {
            text
          }
          link {
            link_type
            url
            target
          }
        }
      }
    }
  }
`;
